<template>
  <Sheet :value="value" no-padding width="100vw" @input="closeFileSheet">
    <!-- title -->

    <template #title>
      <div id="file">
        <BaseActionButton
          v-if="menuActions"
          v-tooltip.top="'files'"
          is-flat
          icon="eva-menu-2"
          @click="$emit('show-files')"
        />

        <div class="avatar q-ml-sm">
          <FileIcon :mime-type="type" />
        </div>

        <div class="q-ml-md">
          <div class="filename">
            {{ file.name }}

            <q-chip
              v-if="file.checkinStatus === 2"
              color="red"
              text-color="white"
              class="chips red-hover ellipsis"
              style="height: 20px; font-size: 12px"
            >
              Readonly
            </q-chip>
          </div>

          <div class="meta">
            <div>Archived At: {{ $day.format(file.createdAt) }}</div>
            <BaseIcon name="mdi-circle-small" />
            <div>Archived By: {{ file.createdBy }}</div>
            <BaseIcon name="mdi-circle-small" />
            <div>File Size: {{ fileSize }}</div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="menuActions" #actions>
      <BaseActionButton
        v-if="checkTenant"
        v-tooltip.top="'Data Extraction'"
        icon="mdi-creation"
        color="primary"
        no-border
        is-flat
        size="20px"
        class="q-ml-xs btnColor"
        @click="summarizeModal = true"
      />
      <div class="row q-mr-sm">
        <q-chip
          v-if="
            file.checkinStatus === 2 &&
            file.checkinBy === $store.state.session.email
          "
          outline
          color="orange"
          clickable
          class="chips ellipsis"
          style="height: 25px"
          @click="showCheckinUploadFile(file)"
        >
          <q-avatar
            icon="mdi-file-upload-outline"
            color="orange"
            text-color="white"
          />
          CheckIn
        </q-chip>
      </div>

      <div
        v-if="isSignProcessFound && file.checkinStatus === 0"
        class="row q-mr-sm"
      >
        <q-chip
          outline
          color="primary"
          clickable
          class="chips ellipsis"
          style="height: 25px"
          @click="checkSignProcess"
        >
          <q-avatar
            icon="mdi-file-outline"
            color="primary"
            text-color="white"
          />
          Request for Sign
        </q-chip>
      </div>

      <BaseActionButton
        v-tooltip.top="'Actions'"
        is-flat
        icon="eva-menu"
        class="q-mr-sm"
      >
        <q-menu
          transition-show="scale"
          transition-hide="scale"
          anchor="bottom end"
          self="top right"
        >
          <BaseScrollbar
            :height="scrollbarHeight"
            width="240px"
            class="q-pb-sm"
          >
            <!-- columns -->

            <ListItem
              v-for="action in actionList"
              :key="action.id"
              :icon="action.icon"
              :label="action.label"
              :visible="action.visible"
              :disabled="action.disable"
              :sub-list="action.subMenu || []"
              @click="updateColumns(action)"
              @subListAction="checkWorkflow"
            >
            </ListItem>
          </BaseScrollbar>

          <!-- ... -->
        </q-menu>
      </BaseActionButton>
    </template>

    <div id="content-wrapper">
      <div v-if="menuActions" class="file-list">
        <iframe
          id="file-frame"
          ref="myFiles"
          :src="fileLink()"
          frameborder="0"
        ></iframe>
      </div>

      <div v-if="!documentEdit" class="aside">
        <div class="action-bar">
          <Tabs v-model="tab" :tabs="tabs" />
        </div>
        <BaseLoadingBarSheet v-if="loadingBar" />
        <div>
          <FileDetails
            v-if="tab === 'METADATA'"
            :file="file"
            :repository-field="repositoryField"
            :file-data="fileData"
            :is-retention-required="menuActions"
            :retention-list="retentionList"
            @refresh="getFileRetentions"
          />
          <Comments
            v-if="tab === 'COMMENTS'"
            :repository-id="file.repositoryId"
            :item-id="file.id"
          />
          <DocumentHistory
            v-if="tab === 'HISTORY'"
            :history="history"
            @get="getFileHistory"
          />
        </div>
      </div>
    </div>

    <Password
      :value="showPasswordModal"
      :header="selectList"
      :repository-id="file.repositoryId"
      :item-id="file.id"
      @close="showPasswordModal = false"
    ></Password>

    <MetaDataEdit
      :value="showMetaDataEditModal"
      :file="file"
      :repository-field="repositoryField"
      :file-data="fileData"
      edit-from-file-sheet
      @close="showMetaDataEditModal = false"
      @save="saveMetaData"
    />

    <EncryptDecrypt
      :value="showEncryptDecryptModal"
      :header="selectList"
      @close="showEncryptDecryptModal = false"
    ></EncryptDecrypt>

    <HidePage
      :value="showHidePageModal"
      :header="selectList"
      @close="showHidePageModal = false"
    ></HidePage>

    <Task :value="showTaskModal" @close="showTaskModal = false"></Task>

    <Alert :value="showAlertModal" @close="showAlertModal = false"></Alert>

    <CheckedInOut
      :value="showCheckedInOutModal"
      :header="selectList"
      :file="file"
      @close="showCheckedInOutModal = false"
      @checkout="closeFileSheet"
      @downloadFile="fileDownload"
    ></CheckedInOut>

    <Delete
      :value="showDeleteModal"
      :header="selectList"
      :repository-id="file.repositoryId"
      :item-id="file.id"
      @close="closeDeleteFile"
    ></Delete>

    <AddVault
      :value="showAddVaultModal"
      @close="showAddVaultModal = false"
    ></AddVault>

    <EmailSharing
      v-model="emailSharingSheet"
      :repository-id="file.repositoryId"
      :file-list.sync="selectedFileList"
    ></EmailSharing>

    <Sheet
      v-model="processSheet"
      :width="formWidth"
      has-footer
      no-padding
      @input="closeProcessSheet"
    >
      <!-- title -->

      <template #title> {{ headerTitle }} </template>

      <!-- ... -->

      <!-- content -->
      <div class="row">
        <div class="col">
          <!-- form -->
          <BaseScrollbar height="calc(100vh - 115px)">
            <div v-if="formId">
              <RenderForm
                :form-id="formId"
                :panels="panels"
                :secondary-panels="secondaryPanels"
                :form-settings="formSettings"
                :form-model="formModel"
                :save-action.sync="saveAction"
                :workflow-form-model.sync="workflowFormModel"
                :enable-controls="_enableControls"
              />
            </div>
            <div v-if="documentSignProcess">
              <div class="fileFrame">
                <div class="file-list">
                  <iframe
                    id="file-frame"
                    :src="itemLink()"
                    frameborder="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </BaseScrollbar>
        </div>

        <div class="col-auto q-mr-sm leftBorder">
          <template v-for="processTab in processTabs">
            <BaseActionButton
              v-if="
                processTab.value !== 'TASK' ||
                (processTab.value === 'TASK' && addTaskEnabled)
              "
              :key="processTab.id"
              v-tooltip.left="processTab.label"
              is-flat
              no-border
              :icon="processTab.icon"
              color="primary"
              class="tabButtons"
              @click="showLinks(processTab.value)"
            >
              <span v-if="processTab.count" class="badge"
                >{{ processTab.count }}
              </span>
            </BaseActionButton>
          </template>
        </div>
        <!-- ... -->
      </div>

      <!-- ... -->

      <!-- footer -->
      <template v-if="!documentSignProcess" #footer>
        <div v-if="actions" class="footer row">
          <template v-for="action in actions">
            <BaseButton
              v-if="
                action === 'Approve' ||
                action === 'Complete' ||
                action === 'Rightsize'
              "
              :key="action"
              :label="action"
              color="positive"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else-if="
                action === 'Reject' ||
                action === 'Terminate' ||
                action === 'Force Close'
              "
              :key="action"
              :label="action"
              color="red"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else-if="action === 'Forward' || action === 'Reply'"
              :key="action"
              :label="action"
              color="orange"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
            <BaseButton
              v-else
              :key="action"
              :label="action"
              class="q-ml-md"
              @click="validateRequest(action)"
            />
          </template>
        </div>
      </template>
      <!-- ... -->
    </Sheet>

    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
        />

        <BaseActionButton
          v-tooltip.top="'upload documents'"
          icon="mdi-upload"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="$refs.attachments.click()"
        />
      </template>

      <!-- ... -->

      <!-- content -->
      <div>
        <input
          ref="attachments"
          type="file"
          class="hidden"
          @change="uploadWflowAttachments"
        />
        <template v-for="attachedFile in attachmentList">
          <div :key="attachedFile.id" class="attachmentList">
            <div class="title row">
              <div class="col-auto">
                <FileIcon
                  :mime-type="fileType(attachedFile.name)"
                  class="mini-avatar q-mr-sm"
                />
              </div>
              <div class="col" @click="openFile(attachedFile)">
                {{ attachedFile.name }}
              </div>
              <div class="col-auto">
                <BaseActionButton
                  v-tooltip.top="'delete'"
                  icon="eva-trash-2-outline"
                  color="red"
                  no-border
                  is-flat
                  size="20px"
                  @click.stop="attachmentDelete(attachedFile)"
                />
              </div>
            </div>

            <div class="meta">
              <!-- created at -->

              <div>{{ $day.format(attachedFile.createdAt) }}</div>

              <!-- ... -->

              <!-- created by -->

              <template v-if="attachedFile.createdBy">
                <BaseIcon name="mdi-circle-small" />
                <div>{{ attachedFile.createdByEmail }}</div>
              </template>

              <!-- ... -->

              <!-- size -->

              <template v-if="attachedFile.size">
                <BaseIcon name="mdi-circle-small" />
                <div>{{ attachedFileSize(attachedFile.size) }}</div>
              </template>

              <!-- ... -->
            </div>
          </div>
        </template>
      </div>
    </Sheet>
    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      custom-height="30"
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <!-- content -->
      <div>
        <BaseScrollbar height="calc(100vh - 155px)">
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail
              "
              :text="commentsText(row)"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="bgCommentsColor(row)"
              class="q-ma-sm"
            />
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div class="col q-pa-sm borderTop">
        <div class="row">
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm commentAction"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm commentAction"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>

    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>File Size: {{ attachedFileSize(selectedFile.size) }}</div>
            </div>
          </div>
        </div>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            :src="attachedFileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>

    <Sheet v-model="taskSheet" width="30vw" @input="closeTaskSheet">
      <!-- title -->

      <template #title> Tasks </template>

      <!-- ... -->

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
        />

        <BaseActionButton
          v-tooltip.top="'Add Task'"
          icon="mdi-plus"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="addTask"
        />
      </template>

      <!-- content -->
      <div>
        <template v-for="task in taskEntries">
          <div :key="task.id" class="taskEntries">
            <div class="col-12">
              <div class="row">
                <div
                  class="col-auto q-mr-md"
                  style="margin-top: 3px"
                  @click="$emit('viewTask', task.id)"
                >
                  {{ task.name }}
                </div>
                <q-chip
                  size="10px"
                  :color="getColor(task.priority)"
                  text-color="white"
                >
                  {{ task.priority }}
                </q-chip>
                <q-chip
                  size="10px"
                  :color="getColor(task.status)"
                  text-color="white"
                >
                  {{ task.status }}
                </q-chip>
              </div>
            </div>

            <div class="meta row">
              <div class="col-12">Assigned To: {{ task.assigned }}</div>
              <div class="col-12">
                Start Date: {{ $day.format(task.startDate, false) }}
                <BaseIcon name="mdi-circle-small" />
                End Date: {{ $day.format(task.endDate, false) }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </Sheet>

    <AddTask
      v-if="taskFormId"
      :value="addTaskSheet"
      :form-settings="addTaskForm.settings"
      :panels="addTaskForm.panels"
      :secondary-panels="addTaskForm.secondaryPanels"
      :form-id="taskFormId"
      :repository-id="taskRepositoryId"
      :task-model="selectedTask"
      :document-task="true"
      :file="file"
      :mode="taskMode"
      @close="addTaskSheet = false"
      @addEntry="addTaskEntry"
    ></AddTask>

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="advancedCommentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <BaseButton
          label="Post"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <Sheet v-model="showCheckinModal" width="470px" has-footer @input="reset">
      <template #title> Upload File </template>

      <template #default>
        <!-- <BaseScrollbar height="calc(100vh - 219px)"> -->
        <Dropzone
          v-if="!filesToUpload.length"
          :show-workspace-repository="false"
          :workspace-id="Number($route.query.workspaceId)"
          :repository-id="Number(file.repositoryId)"
          :has-multiple="false"
          @upload="queueFilesForUpload"
        />

        <FormFieldError
          v-if="filesError"
          class="q-mx-md"
          error="Please upload file"
        />

        <UploadingFileList
          v-if="filesToUpload.length"
          :files="filesToUpload"
          :show-progress="showProgress"
          :show-delete-btn="showDeleteBtn"
          @delete="deleteUploadFile"
        />
        <div class="q-px-md">
          <ValidationObserver ref="checkin">
            <!-- field -->

            <ValidationProvider
              v-slot="{ errors }"
              name="comment"
              :rules="{ required: true }"
            >
              <TextAreaField
                v-model="comments"
                label="Purpose for checking in"
                is-mandatory
                :error="errors[0]"
                class="q-mb-lg"
              />
            </ValidationProvider>

            <!-- ... -->
          </ValidationObserver>
        </div>
      </template>

      <template #footer>
        <BaseButton is-flat label="clear" class="q-mr-sm" @click="reset" />

        <BaseButton label="save" @click="uploadFile" />
      </template>
    </Sheet>

    <Modal
      v-model="signProcessModal"
      width="40vw"
      height="60vh"
      has-footer
      @input="signProcessModal = false"
    >
      <!-- title -->

      <template #title> Document Sign</template>

      <!-- ... -->

      <template #default>
        <div class="field-wrapper">
          <SingleChoiceField
            v-model="signType"
            label="Signature Type"
            is-mandatory
            :options="signTypes"
            :options-per-line="2"
            class="q-mb-lg"
          />

          <SingleChoiceField
            v-model="orderType"
            label="Order Type"
            is-mandatory
            :options="orderTypes"
            :options-per-line="2"
            class="q-mb-lg"
          />
        </div>
      </template>

      <template #footer>
        <BaseButton label="Continue" color="primary" @click="openSignProcess" />
      </template>
    </Modal>

    <Summarizing
      v-model="summarizeModal"
      :itemid="file.id"
      :repository-id="file.repositoryId"
      :page="'REPOSITORY'"
      @input="summarizeModal = false"
    />
  </Sheet>
</template>

<script>
import formatBytes from "@/helpers/format-bytes.js";
import {
  user,
  repository,
  workflow,
  form,
  uploadAndIndex,
  menu,
  report,
} from "@/api/factory.js";

import Sheet from "@/components/common/popup/Sheet.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import FileDetails from "./file-data/FileDetails.vue";
import Comments from "./comments/FileComments.vue";
import Tabs from "@/components/common/tabs/Tabs.vue";
import ListItem from "@/components/common/ListItem.vue";
import Password from "@/views/repositories/views/repository-browse-and-trash/components/components/Password";
import MetaDataEdit from "@/views/repositories/views/repository-browse-and-trash/components/components/MetaDataEdit";
import EncryptDecrypt from "@/views/repositories/views/repository-browse-and-trash/components/components/EncryptDecrypt";
import HidePage from "@/views/repositories/views/repository-browse-and-trash/components/components/HidePage";
import Task from "@/views/repositories/views/repository-browse-and-trash/components/components/Task";
import Alert from "@/views/repositories/views/repository-browse-and-trash/components/components/Alert";
import CheckedInOut from "@/views/repositories/views/repository-browse-and-trash/components/components/CheckedInOut";
import Delete from "@/views/repositories/views/repository-browse-and-trash/components/components/Delete";
import AddVault from "@/views/repositories/views/repository-browse-and-trash/components/components/AddVault";
import EmailSharing from "@/components/common/EmailSharing.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import axios from "axios";
import AddTask from "@/views/tasks/views/tasks-browse-and-trash/components/AddTask";
import {
  pdfSupport,
  htmlSupport,
  fileSupport,
  otherFilePDFSupport,
} from "@/helpers/file-format.js";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import Modal from "@/components/common/popup/Modal.vue";
import DocumentHistory from "@/components/common/display/item-details/components/DocumentHistory.vue";
import Dropzone from "@/views/upload-and-index/views/uploader/components/Dropzone";
import UploadingFileList from "@/views/upload-and-index/views/uploader/components/UploadingFileList";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapState } from "vuex";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import Summarizing from "@/components/common/Summarizing.vue";

export default {
  name: "FileSheet",

  components: {
    Sheet,
    FileIcon,
    Tabs,
    FileDetails,
    Comments,
    ListItem,
    Password,
    MetaDataEdit,
    EncryptDecrypt,
    HidePage,
    Task,
    Alert,
    CheckedInOut,
    Delete,
    AddVault,
    EmailSharing,
    RenderForm,
    TextAreaField,
    AddTask,
    TextBuilderField,
    Modal,
    DocumentHistory,
    Dropzone,
    UploadingFileList,
    ValidationObserver,
    ValidationProvider,
    FormFieldError,
    SingleChoiceField,
    Summarizing,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    file: {
      type: Object,
      required: true,
    },

    repositoryField: {
      type: Array,
      required: true,
    },

    options: {
      type: Object,
      default: () => {},
    },

    menuActions: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      tab: "METADATA",
      tabs: [
        {
          id: this.$nano.id(),
          label: "Metadata",
          value: "METADATA",
        },
        {
          id: this.$nano.id(),
          label: "Comments",
          value: "COMMENTS",
        },
        {
          id: this.$nano.id(),
          label: "History",
          value: "HISTORY",
        },
      ],
      actionList: [
        {
          id: "Indexing_Change",
          key: "metadataEdit",
          label: "Metadata Edit",
          icon: "eva-edit-2-outline",
          visible: true,
          disable: true,
        },
        {
          id: "Set_Password",
          key: "password",
          label: "Set Password",
          icon: "mdi-lock-outline",
          visible: true,
          disable: true,
        },
        {
          id: "Remove_Password",
          key: "password",
          label: "Remove Password",
          icon: "mdi-lock-minus-outline",
          visible: true,
          disable: true,
        },
        // {
        //   id: "Encrypt",
        //   label: "Encrypt",
        //   icon: "mdi-eye-off-outline",
        // },
        // {
        //   id: "Decrypt",
        //   label: "Decrypt",
        //   icon: "mdi-eye-outline",
        // },
        {
          id: "Add_To_Vault",
          key: "vault",
          label: "Add To Vault",
          icon: "mdi-eye-off-outline",
          visible: true,
          disable: true,
        },
        {
          id: "Hide_Pages",
          key: "hidePages",
          label: "Hide Pages",
          icon: "mdi-book-open-page-variant-outline",
          visible: true,
          disable: true,
        },
        {
          id: "Task",
          key: "task",
          label: "Task",
          icon: "mdi-send-outline",
          visible: true,
          disable: false,
        },
        {
          id: "Initiate_Workflow",
          key: "workFlow",
          label: "Initiate Workflow",
          icon: "mdi-sitemap",
          subMenu: [],
          visible: true,
          disable: false,
        },
        {
          id: "Alert",
          key: "alert",
          label: "Alert",
          icon: "mdi-file-alert-outline",
          visible: true,
          disable: true,
        },
        {
          id: "CheckIn",
          key: "checkInAndOut",
          label: "CheckIn",
          icon: "mdi-file-upload-outline",
          visible: true,
          disable: true,
        },
        {
          id: "CheckOut",
          key: "checkInAndOut",
          label: "CheckOut",
          icon: "mdi-file-download-outline",
          visible: true,
          disable: true,
        },
        {
          id: "Delete",
          key: "deleteFile",
          label: "Delete",
          icon: "mdi-file-remove-outline",
          visible: true,
          disable: true,
        },
        {
          id: "Email_Sharing",
          key: "emailSharing",
          label: "Email Sharing",
          icon: "mdi-email-outline",
          visible: true,
          disable: true,
        },
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
          visible: true,
          disable: true,
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
          visible: true,
          disable: true,
        },
        {
          id: "Links",
          key: "links",
          label: "Links",
          icon: "mdi-link-variant",
          visible: true,
          disable: true,
        },
      ],
      showPasswordModal: false,
      selectList: {},
      showMetaDataEditModal: false,
      showEncryptDecryptModal: false,
      showHidePageModal: false,
      showTaskModal: false,
      showAlertModal: false,
      showCheckedInOutModal: false,
      showDeleteModal: false,
      showAddVaultModal: false,
      emailSharingSheet: false,
      selectedFileList: [],
      processSheet: false,
      headerTitle: "",
      fileData: [],
      workflows: [],
      formId: "",
      form: {},
      formModel: {},
      workflowFormModel: {},
      actions: [],
      saveAction: "",
      processTabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "print",
          value: "PRINT",
          icon: "mdi-printer",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "task",
          value: "TASK",
          icon: "eva-cube-outline",
          count: 0,
        },
      ],
      attachmentSheet: false,
      commentsSheet: false,
      commentText: "",
      commentsList: [],
      attachmentList: [],
      fileSheet: false,
      selectedFile: {},
      taskSheet: false,
      taskEntries: [],
      workflowInit: {},
      taskFormId: 0,
      taskRepositoryId: 0,
      addTaskForm: {},
      addTaskSheet: false,
      taskEntryId: [],
      taskEntryList: [],
      taskMode: "",
      selectedTask: {},
      formWidth: "100vw",
      advancedCommentsSheet: false,
      advancedCommentText: "",
      history: [],
      fileAction: "",
      addTaskEnabled: false,
      showCheckinModal: false,
      filesToUpload: [],
      comments: "",
      showProgress: false,
      filesError: false,
      showDeleteBtn: true,
      retentionList: [],
      isSignProcessFound: false,
      documentSignProcess: false,
      signWorkflow: {},
      signAction: "",
      signProcessModal: false,
      signType: 0,
      signTypes: [
        {
          id: this.$nano.id(),
          label: "E-Signature",
          value: 0,
        },
        {
          id: this.$nano.id(),
          label: "Digital Signature",
          value: 1,
        },
      ],
      orderType: 0,
      orderTypes: [
        {
          id: this.$nano.id(),
          label: "Sequential",
          value: 1,
        },
        {
          id: this.$nano.id(),
          label: "Non-Sequential",
          value: 0,
        },
      ],
      watchViewerClose: null,
      watchViewerEdit: null,
      transactionUserIds: [],
      signerSettings: {},
      summarizeModal: false,
      selectedUploadFile: [],
      repositoryDetails: {},
      userList: [],
      documentEdit: false,
    };
  },

  computed: {
    ...mapState(["loadingBar"]),

    checkTenant() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        origin === "http://10.16.7.1" ||
        origin === "http://172.16.1.118"
      ) {
        return false;
      }
      return true;
    },

    type() {
      if (!this.file.name) {
        return "";
      }
      return this.file.name.split(".").reverse()[0].slice(0, 4);
    },

    fileSize() {
      return formatBytes(this.file.size);
    },

    scrollbarHeight() {
      const height = this.actionList.length * 36;
      return height > 216 ? "276px" : `${height + 58}px`;
    },

    formName() {
      if (!this.formId) {
        return "";
      }
      return this.form.settings.general.name;
    },

    formDescription() {
      if (!this.formId) {
        return "";
      }

      return this.form.settings.general.description;
    },

    panels() {
      if (!this.formId) {
        return [];
      }
      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.formId) {
        return [];
      }
      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.formId) {
        return {};
      }
      return this.form.settings;
    },

    updatedActionList() {
      const updatedList = [...this.actionList]; // Create a copy of actionList
      updatedList.forEach((action) => {
        if (action.key in this.options) {
          // Set the disable property of the action based on the options value
          action.disable = this.options[action.key] === "true" ? false : true;
          if (action.key === "download" && !action.disable) {
            this.fileAction = "";
            this.fileAction += "download";
          } else if (action.key === "print" && !action.disable) {
            this.fileAction += ",print";
          }
        }
      });
      return updatedList;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      this.panels.forEach((panel) => {
        if (panel.fields.length) {
          let panelFields = panel.fields.filter(
            (field) =>
              field.type === "SINGLE_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE" ||
              field.type === "MULTI_SELECT"
          );
          if (panelFields.length) {
            panelFields.forEach((row) => {
              if (row.settings.validation.enableSettings) {
                if (row.settings.validation.enableSettings.length) {
                  let controls = [];
                  row.settings.validation.enableSettings.forEach((tblrow) => {
                    if (tblrow.controls.length) {
                      controls.push({
                        value: tblrow.value,
                        childControls: tblrow.controls,
                      });
                      tblrow.controls.forEach((control) => {
                        if (controls.indexOf(control) < 0) {
                          listControls.push(control);
                        }
                      });
                    }
                  });
                  if (controls.length) {
                    enableControls.push({
                      id: row.id,
                      parentControls: controls,
                    });
                  }
                }
              }
            });
          }
        }
      });
      return {
        listControls: listControls,
        controls: enableControls,
      };
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      async handler() {
        if (this.file.id && this.value) {
          this.getUserList();
          this.getItemDetails();
          this.getFileRetentions();
          this.getWorkflows();
          this.isSignProcessFound = false;
          if (this.file.name) {
            let ext = this.file.name.split(".").pop();
            if (ext.toLowerCase() === "pdf") {
              this.getSignWorkflow();
            }
          }
          this.getFileHistory();
          this.getTaskForm();
          if (this.file.repositoryId) {
            this.getFolderSecurity();
          }
          if (this.file.hasPassword) {
            if (this.$store.state.session.id == this.file.encryptedBy) {
              let password = this.actionList.findIndex((item) => {
                return item.id === "Set_Password";
              });
              this.actionList[password].label = "Change Password";
              this.actionList[1].visible = true;
              this.actionList[2].visible = true;
            } else {
              this.actionList[1].visible = false;
              this.actionList[2].visible = false;
            }
          } else {
            this.actionList[1].visible = true;
            this.actionList[2].visible = false;
          }

          let checkin = this.actionList.findIndex((item) => {
            return item.id === "CheckIn";
          });
          let checkout = this.actionList.findIndex((item) => {
            return item.id === "CheckOut";
          });

          if (this.file.checkinStatus) {
            if (
              this.file.checkinStatus === 2 &&
              this.file.checkinBy === this.$store.state.session.email
            ) {
              if (checkin >= 0) {
                this.actionList[checkin].disable = false;
                this.actionList[checkout].disable = true;
              }
            } else if (
              this.file.checkinStatus === 1 &&
              this.file.checkinBy === this.$store.state.session.email
            ) {
              if (checkout >= 0) {
                this.actionList[checkout].disable = false;
                this.actionList[checkin].disable = true;
              }
            }
          } else {
            if (checkout >= 0) {
              this.actionList[checkout].disable = false;
              this.actionList[checkin].disable = true;
            }
          }
        }
      },
    },

    workflowFormModel: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowFormModel && this.saveAction) {
          this.initiateWorkflow();
        }
      },
    },

    options: {
      deep: true,
      immediate: true,
      handler() {
        this.actionList = this.updatedActionList;
      },
    },
  },

  methods: {
    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "https://trial.ezofis.com"
      );
      if (this.file.name) {
        let ext = this.file.name.split(".").pop();
        if (pdfSupport(ext)) {
          let action = "";
          if (this.fileAction) {
            action = `&action=${this.fileAction}`;
          }
          if (
            origin === "https://ag-appsvc01.azurewebsites.net" ||
            origin === "https://ag-appsvc05.azurewebsites.net"
          ) {
            return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.file.repositoryId}&itemId=${this.file.id}&type=2&filename=${this.file.name}`;
          } else if (
            origin === "https://trial.ezofis.com" &&
            !otherFilePDFSupport(ext)
          ) {
            if (this.isSignProcessFound && this.file.checkinStatus === 0) {
              localStorage.setItem("viewerClose", false);
              localStorage.setItem("viewerEdit", false);
              this.watchViewerEdit = setInterval(
                () => this.checkViewerEdit(),
                1000
              );
              this.watchViewerClose = setInterval(
                () => this.checkViewerClose(),
                1000
              );
            }
            return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.file.repositoryId}&itemId=${this.file.id}&type=2&filename=${this.file.name}${action}`;
          } else if (origin === "https://app.ezofis.com1") {
            return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.file.repositoryId}&itemId=${this.file.id}&type=2&filename=${this.file.name}${action}`;
          } else {
            return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.file.repositoryId}&itemId=${this.file.id}&type=2&filename=${this.file.name}${action}`;
          }
        } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
          localStorage.setItem("viewerClose", false);
          localStorage.setItem("viewerEdit", false);
          this.watchViewerEdit = setInterval(
            () => this.checkViewerEdit(),
            1000
          );
          this.watchViewerClose = setInterval(
            () => this.checkViewerClose(),
            1000
          );
          return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.file.repositoryId}&itemId=${this.file.id}&type=2&filename=${this.file.name}`;
        } else {
          return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.file.repositoryId}/${this.file.id}/2`;
        }
      } else {
        return "";
      }
    },

    closeFileSheet() {
      this.$emit("input", false);
    },

    updateColumns(column) {
      this.selectList = {};
      if (column.id === "Set_Password" || column.id === "Remove_Password") {
        this.showPasswordModal = true;
        this.selectList = column;
      } else if (column.id === "Indexing_Change") {
        this.showMetaDataEditModal = true;
      } else if (column.id === "Encrypt" || column.id === "Decrypt") {
        this.showEncryptDecryptModal = true;
        this.selectList = column;
      } else if (
        column.id === "Hide_Pages" &&
        this.file &&
        this.file.name &&
        this.file.name.endsWith(".pdf")
      ) {
        this.showHidePageModal = true;
        this.selectList = column;
      } else if (column.id === "Task") {
        //this.showTaskModal = true;
        if (this.taskEntries.length) {
          this.taskSheet = true;
        } else {
          this.addTask();
        }
      } else if (column.id === "Alert") {
        this.showAlertModal = true;
      } else if (column.id === "CheckIn" || column.id === "CheckOut") {
        if (column.id === "CheckOut") {
          this.showCheckedInOutModal = true;
        } else {
          this.itemId = this.file.id;
          this.showCheckinModal = true;
        }
        this.selectList = column;
      } else if (column.id === "Delete") {
        this.showDeleteModal = true;
        this.selectList = column;
      } else if (column.id === "Add_To_Vault") {
        this.showAddVaultModal = true;
      } else if (column.id === "Email_Sharing") {
        this.selectedFileList = [];
        this.selectedFileList.push({
          id: this.file.id,
          name: this.file.name,
        });
        this.emailSharingSheet = true;
      } else if (column.id === "Print") {
        // console.log(this.$refs.myFiles.contentWindow);
        // this.$refs.myFiles.print();
        // this.$refs.myFiles.contentWindow.print();
        if (this.file.name) {
          let ext = this.file.name.split(".").pop();
          if (pdfSupport(ext)) {
            localStorage.setItem("filePrint", true);
            localStorage.setItem("filePrintId", this.file.id);
          } else {
            this.$refs.myFiles.contentWindow.print();
          }
        }
        this.filePrintSession();
      } else if (column.id === "Initiate_Workflow") {
        //
      } else if (column.id === "Download") {
        let link = "";
        link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.file.repositoryId}/${this.file.id}/2`;
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    closeProcessSheet() {
      this.processSheet = false;
    },

    showLinks(tab) {
      if (tab === "ATTACHMENTS") {
        this.attachmentSheet = true;
      } else if (tab === "COMMENTS") {
        this.commentsSheet = true;
      } else if (tab === "PRINT") {
        window.print();
      } else if (tab === "TASK") {
        if (this.taskEntries.length) {
          this.taskSheet = true;
        } else {
          this.addTask();
        }
      }
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },

    closeCommentsSheet() {
      this.closeCommnetsSheet = false;
    },

    commentsText(row) {
      if (row.processId) {
        return [
          `<b>${row.workflowName} : ${row.requestNo}</b><br/> ${row.comments}`,
        ];
      } else if (row.checkinStatus === 2) {
        return [`<b>CheckOut:</b><br/> ${row.comments}`];
      } else if (row.checkinStatus === 1) {
        return [`<b>CheckIn:</b><br/> ${row.comments}`];
      }
      return [row.comments];
    },

    bgCommentsColor(row) {
      if (row.processId) {
        return "orange-1";
      } else if (row.checkinStatus === 2) {
        return "red-1";
      } else if (row.checkinStatus === 1) {
        return "green-1";
      } else if (row.createdBy === this.$store.state.session.id) {
        return "primary-1";
      }
      return "secondary-1";
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    openFile(fileObj) {
      this.selectedFile = fileObj;
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        window.open(this.fileLink());
      } else {
        this.fileSheet = true;
      }
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    attachedFileSize(size) {
      return formatBytes(size);
    },

    attachedFileLink() {
      return `${process.env.VUE_APP_API_URL}/uploadandindex/view?id=${this.selectedFile.id}&type=1`;
    },

    closeTaskSheet() {
      this.taskSheet = false;
    },

    validateRequest(action) {
      this.saveAction = action;
    },

    addTask() {
      this.selectedTask = {};
      this.taskMode = "NEW";
      this.addTaskSheet = true;
    },

    addTaskEntry(formId, entryId) {
      this.taskEntryId.push({
        formId: formId,
        formEntryId: entryId,
      });
      this.getTaskFormEntry(formId, entryId);
    },

    getColor(label) {
      if (label === "Closed" || label === "High") {
        return "red";
      } else if (label === "In Progress" || label === "Medium") {
        return "warning";
      } else if (label === "Open" || label === "Low") {
        return "secondary";
      } else if (label === "ReOpen") {
        return "primary";
      } else {
        return "primary";
      }
    },

    disableAction(option, action) {
      if (action.id === "Hide_Pages" && option && this.file && this.file.name) {
        return !this.file.name.endsWith(".pdf");
      }
      return false; // Default to not disabled
    },

    closeDeleteFile() {
      this.showDeleteModal = false;
      this.$emit("input", false);
    },

    showCheckinUploadFile(item) {
      if (item.checkinStatus === 2) {
        // this.$emit("showCheckin", item);
        this.showCheckinModal = true;
        console.log(item);
        this.itemId = item.id;
      } else {
        this.showCheckedInOutModal = true;
        let checkout = this.actionList.findIndex((item) => {
          return item.id === "CheckOut";
        });
        this.selectList = this.actionList[checkout];
      }
    },

    queueFilesForUpload(files) {
      this.filesError = false;
      this.filesToUpload.push(...files);
    },

    fileDownload() {
      let link = "";
      link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.file.repositoryId}/${this.file.id}/2`;
      window.open(link, "_blank");
    },

    reset() {
      this.filesToUpload = [];
      this.comments = "";
      this.showCheckinModal = false;
    },

    deleteUploadFile(index) {
      this.filesToUpload.splice(index, 1);
    },

    checkSignProcess() {
      this.signType = 0;
      this.orderType = 1;
      this.signProcessModal = true;
    },

    openSignProcess() {
      this.signProcessModal = false;
      this.actions = [];
      let wflowJson = JSON.parse(this.signWorkflow.flowJson);
      let startBlock = wflowJson.blocks.find((block) => block.type === "START");
      if (startBlock) {
        if (startBlock.settings.initiateMode === "MANUAL") {
          this.headerTitle = `${this.signWorkflow.name} - ${startBlock.settings.label}`;
          let rules = wflowJson.rules.find(
            (rule) => rule.fromBlockId === startBlock.id
          );
          if (rules) {
            this.actions.push(rules.proceedAction);
          }
        }
      }
      localStorage.setItem("userList", JSON.stringify(this.userList));
      localStorage.setItem("viewerClose", false);
      this.watchViewerClose = setInterval(() => this.checkViewerClose(), 1000);
      this.documentSignProcess = true;
      this.processSheet = true;
    },

    itemLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      let domainURL = "";

      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        domainURL = `https://ag-appsvc04.azurewebsites.net/web/viewer.html`;
      } else if (origin === "https://trial.ezofis.com") {
        domainURL = `https://trial.ezofis.com/docsviewer/index.html`;
      } else if (origin === "https://app.ezofis.com1") {
        domainURL = `https://ezdocsviewer.azurewebsites.net/web/viewer.html`;
      } else if (origin === "http://52.172.32.88") {
        domainURL = `http://52.172.32.88/DocsViewer/index.html`;
      } else {
        domainURL = `${origin}/PDFViewer/web/viewer.html`;
      }

      return `${domainURL}?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.file.repositoryId}&itemId=${this.file.id}&wId=${this.signWorkflow.id}&type=2&position=true&signType=${this.signType}&orderType=${this.orderType}`;
    },

    checkViewerClose() {
      if (localStorage.getItem("viewerClose")) {
        if (localStorage.getItem("viewerClose") === "true") {
          clearInterval(this.watchViewerClose);
          localStorage.removeItem("viewerClose");
          if (this.processSheet) {
            this.getSignerSettings();
          } else {
            this.closeFileSheet();
          }
        }
      }
    },

    checkViewerEdit() {
      if (localStorage.getItem("viewerEdit")) {
        if (localStorage.getItem("viewerEdit") === "true") {
          localStorage.removeItem("viewerEdit");
          clearInterval(this.watchViewerEdit);
          this.documentEdit = true;
        }
      }
    },

    /* API Functions */

    async getUserList() {
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }

      this.userList =
        payload &&
        payload.map((user) => ({
          id: user.id,
          value: user.value,
          firstName: user.firstName,
        }));
    },

    async getItemDetails() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await repository.getFileData({
        repositoryId: this.file.repositoryId,
        itemId: this.file.id,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }
      this.fileData = payload;
    },

    async getFileRetentions() {
      this.retentionList = [];
      const { error, payload } = await repository.getRetentions({
        sortBy: { criteria: "", order: "ASC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "repositoryId",
                condition: "IS_EQUALS_TO",
                value: this.file.repositoryId,
              },
              {
                criteria: "itemId",
                condition: "IS_EQUALS_TO",
                value: this.file.id,
              },
            ],
            groupCondition: "",
          },
        ],
        currentPage: 0,
        itemsPerPage: 0,
        mode: "BROWSE",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload === null) {
        return;
      }

      this.retentionList =
        payload &&
        payload.map((rule) => ({
          id: rule.id,
          name: rule.name,
          action: rule.action,
          actionDate: rule.actionDate,
          fieldId: rule.fieldId,
          durationType: rule.durationType,
          duration: rule.duration,
          notifyJson: rule.notifyJson,
        }));
    },

    async getWorkflows() {
      this.workflows = [];
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getWorkflows({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "initiatedBy",
                condition: "CONTAINS",
                value: "Document",
                dataType: "",
              },
              {
                criteria: "flowstatus",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
              {
                criteria: "repositoryId",
                condition: "IS_EQUALS_TO",
                value: this.file.repositoryId,
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 0,
        currentPage: 0,
        hasSecurity: false,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      if (data.length) {
        if (data[0].value.length) {
          data[0].value.forEach((workflow) => {
            let flowJson = JSON.parse(workflow.flowJson);
            let startBlock = flowJson.blocks.find(
              (block) => block.type === "START"
            );
            if (startBlock) {
              if (startBlock.settings.initiateBy.indexOf("USER") > -1) {
                let initiateRights = false;
                if (
                  startBlock.settings.users.indexOf(
                    this.$store.state.session.id
                  ) > -1 ||
                  startBlock.settings.users.indexOf("0") > -1
                ) {
                  initiateRights = true;
                }
                if (
                  startBlock.settings.initiateBy.indexOf("DOMAIN_USER") > -1
                ) {
                  let domain = this.$store.state.session.email.split("@").pop();
                  if (startBlock.settings.userDomains.indexOf(domain) > -1) {
                    initiateRights = true;
                  }
                }
                if (initiateRights) {
                  let review = "";
                  let startRule = flowJson.rules.find(
                    (rule) => rule.fromBlockId === startBlock.id
                  );
                  if (startRule) {
                    review = startRule.proceedAction;
                  }
                  this.workflows.push({
                    id: workflow.id,
                    name: workflow.name,
                    review: review,
                    initiatedBy: workflow.initiatedBy,
                    formId: workflow.wFormId,
                    flowJson: workflow.flowJson,
                  });
                }
              }
            }
          });
          if (this.workflows.length) {
            this.actionList.find((action) => {
              if (action.id === "Initiate_Workflow") {
                action.subMenu = this.workflows;
              }
            });
          }
        }
      }
    },

    async getSignWorkflow() {
      this.isSignProcessFound = false;
      this.signWorkflow = {};
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getWorkflows({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "initiatedBy",
                condition: "CONTAINS",
                value: "DOCUMENT_SIGN",
                dataType: "",
              },
              {
                criteria: "flowstatus",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 0,
        currentPage: 0,
        hasSecurity: false,
      });

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      if (data.length) {
        if (data[0].value.length) {
          this.isSignProcessFound = true;
          data[0].value.forEach((workflow) => {
            let flowJson = JSON.parse(workflow.flowJson);
            let startBlock = flowJson.blocks.find(
              (block) => block.type === "START"
            );
            if (startBlock) {
              let review = "";
              let startRule = flowJson.rules.find(
                (rule) => rule.fromBlockId === startBlock.id
              );
              if (startRule) {
                review = startRule.proceedAction;
              }
              this.signWorkflow = {
                id: workflow.id,
                name: workflow.name,
                review: review,
                initiatedBy: workflow.initiatedBy,
                formId: workflow.wFormId,
                flowJson: workflow.flowJson,
              };
            }
          });
        }
      }
    },

    async checkWorkflow(workflowDet) {
      this.documentSignProcess = false;
      if (
        workflowDet.initiatedBy === "DOCUMENT" ||
        workflowDet.initiatedBy === "DOCUMENT_SIGN"
      ) {
        this.workflowInit = workflowDet;
        this.saveAction = workflowDet.review;
        this.initiateWorkflow();
      } else if (
        workflowDet.initiatedBy === "DOCUMENT_FORM" &&
        workflowDet.formId
      ) {
        this.workflowInit = workflowDet;
        this.actions = [];
        await this.getForm(workflowDet.formId);
        this.formId = String(workflowDet.formId);
        let wflowJson = JSON.parse(workflowDet.flowJson);
        let startBlock = wflowJson.blocks.find(
          (block) => block.type === "START"
        );
        if (startBlock) {
          if (startBlock.settings.initiateMode === "MANUAL") {
            this.headerTitle = `${workflowDet.name} - ${startBlock.settings.label}`;
            let rules = wflowJson.rules.find(
              (rule) => rule.fromBlockId === startBlock.id
            );
            if (rules) {
              this.actions.push(rules.proceedAction);
            }
          }
        }
        const panels = [...this.form.panels, ...this.form.secondaryPanels];

        if (!panels.length) {
          return;
        }
        let formFields = [];
        for (const panel of panels) {
          formFields.push(...panel.fields);
        }
        formFields.forEach((field) => {
          this.fileData.forEach((row) => {
            if (row.name == field.label) {
              this.formModel[field.id] = row.value;
            }
          });
        });
        this.processSheet = true;
      }
    },

    async getForm(formId) {
      const { error, payload } = await form.getForm(formId);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        this.form = JSON.parse(payload.formJson);
      }
    },

    async initiateWorkflow() {
      let uploadedIdList = [];
      if (this.attachmentList.length) {
        this.attachmentList.forEach((file) => {
          uploadedIdList.push(file.id);
        });
      }
      let inputData = {
        workflowId: this.workflowInit.id,
        repositoryId: this.file.repositoryId,
        itemId: this.file.id,
        review: this.saveAction,
        formData: {
          formId: this.workflowInit.formId,
          fields: this.workflowFormModel,
        },
        comments: this.commentsList,
        task: this.taskEntryId,
        userIds: this.transactionUserIds,
        fileIds: uploadedIdList,
      };
      this.saveAction = "";
      this.workflowFormModel = {};

      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await workflow.processTransaction(inputData);
      this.$store.commit("hideLoadingBarSave");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.$alert.success(`${payload.requestNo} Request Initiated`);
      if (this.workflowInit.initiatedBy === "DOCUMENT_SIGN") {
        this.updateSignerSettingWithProcessId(payload);
      }
      this.workflowInit = {};
      this.closeProcessSheet();
    },

    async saveComment() {
      if (this.commentText) {
        this.commentsList.push({
          comments: this.commentText,
          createdAt: new Date().toISOString(),
          createdBy: this.$store.state.session.id,
          fileIds: [0],
        });
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
        this.commentText = "";
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        this.commentsList.push({
          comments: this.advancedCommentText,
          createdAt: new Date().toISOString(),
          createdBy: this.$store.state.session.id,
          fileIds: [0],
        });
        this.tabs.find((tab) => {
          if (tab.value === "COMMENTS") {
            tab.count = this.commentsList.length;
          }
        });
        this.advancedCommentText = "";
        this.advancedCommentsSheet = false;
      }
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.file.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.repositoryDetails = payload;
    },

    async uploadWflowAttachments(e) {
      if (this.repositoryDetails.fieldsType === "STATIC") {
        this.selectedUploadFile = e;
        this.uploadFilename = e.target.files[0].name;
        this.showMetaDataEditModal = true;
      } else {
        //this.uploadAttachments(e);
      }
    },

    async uploadAttachments(e) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("repositoryId", this.file.repositoryId);
      let filename = e.target.files[0].name;
      const ext = filename.split(".").pop();
      if (!fileSupport(ext)) {
        this.$alert.info("This file format is not supported");
        return;
      }
      try {
        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
          method: "POST",
          data: formData,
          headers: {
            Token: this.$store.state.identity.token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });

        const { status, data } = response;
        if (status !== 200) {
          throw "Invalid response";
        }
        let filesList = JSON.parse(data);
        this.attachmentList.push({
          id: Number(filesList.fileId),
          name: file.name,
          createdAt: new Date().toISOString(),
          createdByEmail: this.$store.state.session.email,
          size: file.size,
          initiate: true,
        });
        e.target.value = "";
      } catch (error) {
        console.error(error);
        this.$alert.error(`error attaching file`);
      }
    },

    async attachmentDelete(file) {
      this.$store.commit("showLoadingBar");
      if (file.initiate) {
        const { error } = await uploadAndIndex.deleteUploadedFiles([file.id]);
        if (error) {
          this.$alert.error(error);
          return;
        }
        this.$store.commit("hideLoadingBar");
        this.attachmentList = this.attachmentList.filter(
          (row) => row.id !== file.id
        );
      }
    },

    async getTaskForm() {
      this.taskFormList = [];
      this.addTaskEnabled = false;
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "Task",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 50,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      if (data.length) {
        if (data[0].value.length) {
          this.addTaskEnabled = true;
          data[0].value.forEach((task) => {
            this.taskFormList.push({
              id: task.id,
              label: task.name,
              value: task.id,
              repositoryId: task.repositoryId,
              formJson: task.formJson,
            });
          });
          if (this.taskFormList.length) {
            if (this.taskFormList.length === 1) {
              this.taskFormId = this.taskFormList[0].id;
              this.taskRepositoryId = this.taskFormList[0].repositoryId;
              this.addTaskForm = JSON.parse(this.taskFormList[0].formJson);
              this.getDocumentTaskEntries();
            }
          } else {
            //this.$alert.info("Task form not found");
          }
        }
      }
    },

    async getTaskFormEntry(formId, entryId) {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getFormEntry(formId, entryId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        const panels = [
          ...this.addTaskForm.panels,
          ...this.addTaskForm.secondaryPanels,
        ];

        let formFields = [];
        for (const panel of panels) {
          formFields.push(...panel.fields);
        }
        let taskName = "",
          taskAssign = "",
          startDate = "",
          endDate = "",
          priority = "",
          status = "";
        formFields.forEach((field) => {
          switch (field.label) {
            case "Task Name":
              taskName = payload[0][field.id];
              break;
            case "Task Assign":
              taskAssign = payload[0][field.id];
              break;
            case "Start Date":
              startDate = payload[0][field.id];
              break;
            case "End Date":
              endDate = payload[0][field.id];
              break;
            case "Priority":
              priority = payload[0][field.id];
              break;
            case "Task Status":
              status = payload[0][field.id];
              break;
          }
        });
        this.taskEntries.push({
          id: payload[0].itemid,
          name: taskName,
          assigned: taskAssign,
          startDate: startDate,
          endDate: endDate,
          priority: priority,
          status: status,
        });
      }
    },

    async getDocumentTaskEntries() {
      this.taskEntries = [];
      const { error, payload } = await form.getDocumentTaskEntries(
        this.taskFormId,
        this.file.repositoryId,
        this.file.id
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        const panels = [
          ...this.addTaskForm.panels,
          ...this.addTaskForm.secondaryPanels,
        ];

        let formFields = [];
        for (const panel of panels) {
          formFields.push(...panel.fields);
        }
        let taskName = "",
          taskAssign = "",
          startDate = "",
          endDate = "",
          priority = "",
          status = "";
        formFields.forEach((field) => {
          switch (field.label) {
            case "Task Name":
              taskName = payload[0][field.id];
              break;
            case "Task Assign":
              taskAssign = payload[0][field.id];
              break;
            case "Start Date":
              startDate = payload[0][field.id];
              break;
            case "End Date":
              endDate = payload[0][field.id];
              break;
            case "Priority":
              priority = payload[0][field.id];
              break;
            case "Task Status":
              status = payload[0][field.id];
              break;
          }
        });
        this.taskEntries.push({
          id: payload[0].itemid,
          name: taskName,
          assigned: taskAssign,
          startDate: startDate,
          endDate: endDate,
          priority: priority,
          status: status,
        });
      }
    },

    async filePrintSession() {
      const { error } = await menu.filePrintSession({
        repositoryId: this.file.repositoryId,
        itemId: this.file.id,
        activityId: 4,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async getFileHistory() {
      this.$store.commit("showLoadingBar");
      this.history = [];
      const { error, payload } = await report.getRepositoryData(
        this.file.repositoryId,
        {
          itemId: this.file.id,
          filterBy: [],
          itemsPerPage: 100,
          currentPage: 1,
        }
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        //console.log(payload);
        this.history = payload.data[0].value.map((row) => ({
          activity: row.activity,
          activityBy: row.activityBy,
          activityOn: this.$day.format(row.activityOn),
        }));
      }
    },

    async uploadFile() {
      const isValid = await this.$refs.checkin.validate();

      if (!this.filesToUpload.length) {
        this.filesError = true;
        return;
      }

      if (!isValid) {
        return;
      }
      let file = this.filesToUpload[0];
      let index = 0;
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("repositoryId", this.file.repositoryId);
      formData.append("itemId", this.itemId);
      formData.append("comments", this.comments);

      this.showProgress = true;
      this.showDeleteBtn = false;

      let filename = file.name;
      const ext = filename.split(".").pop();

      if (!fileSupport(ext)) {
        this.filesToUpload.splice(index, 1);
        this.$alert.info("This file format is not supported");
        return;
      }

      try {
        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
          method: "POST",
          data: formData,
          headers: {
            Token: this.$store.state.identity.token,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            const uploadedPercentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            file.uploadedPercentage = uploadedPercentage;
          },
        });

        const { status } = response;
        if (status !== 200) {
          throw "Invalid response";
        }

        this.$alert.success("file updated successfully");
        this.reset();
        this.showCheckinModal = false;
        this.$emit("input", false);

        // this.refreshData();
      } catch (error) {
        console.error(error);
        this.$alert.error(`error uploading file - ${file.name}`);
      }

      // remove file
    },

    async saveMetaData(data) {
      this.$store.commit("showLoadingBar");
      let settings = [];
      let hasAnyEdit = false;
      data.forEach((item) => {
        if (item.oldValue !== item.newValue) {
          hasAnyEdit = true;
          settings.push(item);
        }
      });
      if (!hasAnyEdit) {
        this.$alert.error("Not edited any field");

        return;
      }
      let input = {
        repositoryId: this.file.repositoryId,
        itemId: this.file.id,
        settings: JSON.stringify(settings),
      };
      const { error, payload } = await repository.saveMetaData(input);
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (payload) this.$alert.success("Metadata edit successfully");
      this.getItemDetails();
      // this.$emit("input", false);
    },

    async getFolderSecurity() {
      const { error, payload } = await repository.getRepositorySecurity(
        this.file.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        if (payload.userOptions) {
          const userOptions = payload.userOptions.map((userOption) => ({
            id: userOption.id,
            userName: userOption.email,
            userId: userOption.userId,
            options: JSON.parse(userOption.options),
          }));
          let options = userOptions.find(
            (user) => user.userId === this.$store.state.session.id
          );
          let hasUserEdit = false;
          if (options && options.options) {
            if (options.options.metadataEdit) {
              hasUserEdit = true;
            } else {
              hasUserEdit = false;
            }
          } else {
            hasUserEdit = false;
          }

          const groupOptions = payload.groupOptions.map((groupOption) => ({
            id: groupOption.id,
            groupId: groupOption.groupId,
            options: JSON.parse(groupOption.options),
          }));

          let hasGroupEdit = false;
          if (this.$store.state.session.groups) {
            this.$store.state.session.groups.forEach((group) => {
              let options1 = groupOptions.find(
                (user) => user.groupId === group.id
              );
              if (options1 && options1.options) {
                if (options1.options.metadataEdit) {
                  hasGroupEdit = true;
                }
              }
            });
          }

          let edit = this.actionList.findIndex((item) => {
            return item.id === "Indexing_Change";
          });

          if (edit >= 0) {
            if (hasUserEdit || hasGroupEdit) {
              this.actionList[edit].disable = false;
            } else {
              this.actionList[edit].disable = true;
            }
          }
        }
      }
    },

    async getSignerSettings() {
      this.transactionUserIds = [];
      this.signerSettings = {};
      let inputData = [
        {
          groupCondition: "",
          filters: [
            {
              criteria: "repositoryId",
              condition: "IS_EQUALS_TO",
              value: this.file.repositoryId.toString(),
            },
            {
              criteria: "itemId",
              condition: "IS_EQUALS_TO",
              value: this.file.id.toString(),
            },
          ],
        },
      ];
      try {
        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}/annotation/signSettingsList/${this.$store.state.session.tenantId}/${this.signWorkflow.id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          data: inputData,
        });

        const { status, data } = response;
        if (status !== 200) {
          throw "Invalid response";
        }
        this.signerSettings = JSON.parse(data);
        let signerDet = JSON.parse(this.signerSettings.settingsJson);
        if (signerDet.length) {
          if (this.orderType === 0) {
            signerDet.forEach((row) => {
              if (row.signerType === "External") {
                this.transactionUserIds.push(row.externalSignerId);
              } else {
                this.transactionUserIds.push(row.signerId);
              }
            });
          } else {
            let initSigner = signerDet.find((row) => row.signOrder === 1);
            if (initSigner) {
              let signerId =
                initSigner.signerType === "External"
                  ? initSigner.externalSignerId
                  : initSigner.signerId;

              this.transactionUserIds.push(signerId);
            }
          }
        }
        if (this.transactionUserIds.length) {
          this.checkWorkflow(this.signWorkflow);
        }
      } catch (error) {
        console.error(error);
        this.$alert.error(`error getting signer details`);
      }
    },

    async updateSignerSettingWithProcessId(processDet) {
      try {
        this.signerSettings.processId = processDet.processId;
        let signData = {
          pId: processDet.processId,
          rId: this.signerSettings.repositoryId,
          itemId: this.signerSettings.itemId,
          settingsJson: this.signerSettings.settingsJson,
          isDeleted: 0,
        };
        const response = await axios({
          url: `${process.env.VUE_APP_API_URL}/annotation/signSettings/${this.$store.state.session.tenantId}/${this.signWorkflow.id}/${this.signerSettings.id}`,
          method: "PUT",
          headers: {
            Accept: "application/json",
          },
          data: signData,
        });

        const { status } = response;
        if (status !== 202) {
          throw "Invalid response";
        }
      } catch (error) {
        console.error(error);
        this.$alert.error(`error updating signer details`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#file {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#content-wrapper {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

.action-bar {
  display: flex;
  align-items: center;
  margin: 0px 16px;
}

#sheet {
  .badge {
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
    font-size: 12px;
    background: $primary;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-top: -30px;
    margin-right: -20px;
    position: absolute;
    z-index: 1;
  }

  .leftBorder {
    border-left: 1px solid var(--divider-color);
  }

  #action-btn.tabButtons {
    height: 52px;
    width: 52px;
  }
}

#sheet .attachmentList,
#sheet .taskEntries {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    cursor: pointer;
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .fileFrame {
  min-height: calc(100vh - 120px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet {
  #form-view {
    background-color: var(--body-bg-color);
    margin: auto;
    padding: 16px;

    .header {
      padding: 16px;
      border-radius: 4px;
      background-color: var(--component-bg-color);
      border-top: 4px solid var(--primary);
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      .description {
        @extend .text-sm;
        color: var(--icon-color-inverted);
      }
    }

    .footer {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      left: 0;
      bottom: 16px;
      width: 100%;

      .label {
        @extend .text-sm;
        color: var(--icon-color-inverted);
        margin-right: 4px;
      }

      img {
        height: 16px;
        width: auto;
      }
    }
  }
}

#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }
}
</style>

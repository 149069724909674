<template>
  <div>
    <Modal
      v-model="processHistoryModal"
      :width="externalAccess ? '100vw' : '70vw'"
      :external-access="externalAccess"
      @input="closeModal"
    >
      <!-- title -->

      <template #title>"{{ historyTitle }}" Process History</template>

      <!-- ... -->

      <!-- action -->

      <template #action>
        <BaseActionButton
          v-tooltip.top="'Export'"
          is-flat
          no-border
          icon="mdi-table-arrow-right"
          @click="export_Data"
        />
      </template>
      <!-- ... -->

      <template #default>
        <div id="processHistory">
          <div class="col-12 q-mb-md q-pt-sm">
            <Stepper :stage-level="stageLevel" :current-step="currentStep" />
          </div>

          <table id="history">
            <thead>
              <tr>
                <!-- columns -->

                <th v-for="column in columns" :key="column.id">
                  {{ column.label }}
                </th>

                <!-- ... -->
              </tr>
            </thead>

            <tbody>
              <template v-for="item in processHistoryData">
                <tr :key="item.id">
                  <!-- columns -->

                  <td
                    v-for="column in columns"
                    :key="column.id"
                    class="ellipsis"
                  >
                    <slot
                      :name="column.name"
                      :value="item[column.name]"
                      :item="item"
                    >
                      <template v-if="column.type === 'DATE'">
                        {{ $day.format(item[column.name]) || "-" }}
                      </template>
                      <template v-else-if="column.name === 'stage'">
                        {{ item[column.name] || "-" }}
                        <template v-if="item.subWorkflowHistory">
                          <template v-if="item.subWorkflowHistory.length">
                            <span class="text-secondary"> [CONNECTOR] </span>
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        {{ item[column.name] || "-" }}
                      </template>
                    </slot>
                  </td>

                  <!-- ... -->
                </tr>
                <tr
                  v-if="item.comments.length"
                  :key="item.id"
                  @click="item.showComments = !item.showComments"
                >
                  <td class="comments" colspan="6">
                    <BaseIcon
                      :name="
                        item.showComments
                          ? 'eva-arrow-downward-outline'
                          : 'eva-arrow-forward-outline'
                      "
                      color="primary"
                    ></BaseIcon>
                    Comments ({{ item.comments.length }})
                  </td>
                </tr>
                <tr
                  v-if="item.comments.length && item.showComments"
                  :key="item.id"
                >
                  <td colspan="6">
                    <table id="comments1">
                      <thead>
                        <tr>
                          <!-- columns -->

                          <th class="large">Comments</th>
                          <th class="medium">Commented By</th>
                          <th class="medium">Commented On</th>
                          <th class="medium">Attached In</th>

                          <!-- ... -->
                        </tr>
                      </thead>

                      <tbody>
                        <template v-for="(comment, index) in item.comments">
                          <tr :key="index">
                            <!-- columns -->

                            <td class="commentsText ellipsis">
                              {{ removeTags(comment.comments) || "-" }}
                            </td>
                            <td class="ellipsis">
                              {{ comment.createdByEmail || "-" }}
                            </td>
                            <td class="ellipsis">
                              {{ $day.format(comment.createdAt) || "-" }}
                            </td>
                            <td>
                              <template
                                v-if="
                                  comment.embedFileList &&
                                  comment.embedFileList.length
                                "
                              >
                                <div
                                  class="file-icons"
                                  @click="
                                    openFileByCmd(comment.embedFileList[0])
                                  "
                                >
                                  <FileIcon
                                    :key="`Icon-${index}`"
                                    v-tooltip.top="
                                      comment.embedFileList[0].fileName
                                    "
                                    :mime-type="
                                      fileType(
                                        comment.embedFileList[0].fileName
                                      )
                                    "
                                    class="file-icon q-mr-sm"
                                  />
                                  <a
                                    href="javascript:void(0)"
                                    class="file-link"
                                    @click.prevent
                                    >{{
                                      comment.embedFileList[0].fileName || "-"
                                    }}</a
                                  >
                                </div>
                              </template>
                              <template v-else>
                                {{ "-" }}
                              </template>
                            </td>

                            <!-- ... -->
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr v-if="item.attachments.length" :key="item.id">
                  <td
                    class="attachments"
                    colspan="6"
                    @click="item.showAttachments = !item.showAttachments"
                  >
                    <BaseIcon
                      :name="
                        item.showAttachments
                          ? 'eva-arrow-downward-outline'
                          : 'eva-arrow-forward-outline'
                      "
                      color="primary"
                    ></BaseIcon>
                    Attachments ({{ item.attachments.length }})
                  </td>
                </tr>
                <tr
                  v-if="item.attachments.length && item.showAttachments"
                  :key="item.id"
                >
                  <td colspan="6">
                    <table id="attachments">
                      <thead>
                        <tr>
                          <!-- columns -->

                          <th>File Name</th>
                          <th>Attached By</th>
                          <th>Attached On</th>

                          <!-- ... -->
                        </tr>
                      </thead>

                      <tbody>
                        <template v-for="(file, index) in item.attachments">
                          <tr :key="index">
                            <!-- columns -->

                            <td>{{ file.name || "-" }}</td>
                            <td>{{ file.createdByEmail || "-" }}</td>
                            <td>
                              {{ $day.format(file.createdAt) || "-" }}
                            </td>

                            <!-- ... -->
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <template v-if="item.subWorkflowHistory">
                  <tr v-if="item.subWorkflowHistory.length" :key="item.id">
                    <td
                      class="attachments"
                      colspan="6"
                      @click="
                        item.showSubWorkflowHistory =
                          !item.showSubWorkflowHistory
                      "
                    >
                      <BaseIcon
                        :name="
                          item.showSubWorkflowHistory
                            ? 'eva-arrow-downward-outline'
                            : 'eva-arrow-forward-outline'
                        "
                        color="primary"
                      ></BaseIcon>

                      <template v-if="item.actionStatus === 1">
                        <BaseIcon
                          v-tooltip.top="'Completed'"
                          name="mdi-progress-check"
                          color="green"
                          class="q-ml-xs q-mr-xs"
                        />
                      </template>
                      <template v-else-if="item.actionStatus === 0">
                        <BaseIcon
                          v-tooltip.top="'In-progress'"
                          name="mdi-progress-clock"
                          color="orange"
                          class="q-ml-xs q-mr-xs"
                        />
                      </template>

                      {{ item.subWorkflowHistory[0].requestNo }}
                    </td>
                  </tr>
                  <tr v-if="item.showSubWorkflowHistory" :key="item.id">
                    <td colspan="6">
                      <table id="attachments">
                        <thead>
                          <th v-for="column in columns" :key="column.id">
                            {{ column.label }}
                          </th>
                        </thead>

                        <tbody>
                          <template v-for="items in item.subWorkflowHistory">
                            <tr :key="items.id">
                              <!-- columns -->

                              <td
                                v-for="column in columns"
                                :key="column.id"
                                class="ellipsis"
                              >
                                <slot
                                  :name="column.name"
                                  :value="items[column.name]"
                                  :item="items"
                                >
                                  <template v-if="column.type === 'DATE'">
                                    {{ $day.format(items[column.name]) || "-" }}
                                  </template>

                                  <template v-else>
                                    {{ items[column.name] || "-" }}
                                  </template>
                                </slot>
                              </td>

                              <!-- ... -->
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </table>
        </div>
      </template>
    </Modal>
    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>
                Archived At: {{ $day.format(selectedFiles[0].createdAt) }}
              </div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFiles[0].createdByEmail }}</div>
              <div v-if="selectedFiles[0].size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFiles[0].size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
     

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
             
              >
              </ListItem>
            </BaseScrollbar>

           
          </q-menu>
        </BaseActionButton>
      </template> -->

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myAttachmentFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>
  </div>
</template>
<script>
import { workflow } from "@/api/factory.js";
import Modal from "@/components/common/popup/Modal.vue";
import Stepper from "./Stepper.vue";
import FileIcon from "@/components/common/FileIcon.vue";
import Sheet from "@/components/common/popup/Sheet.vue";
import {
  pdfSupport,
  htmlSupport,
  otherFilePDFSupport,
} from "@/helpers/file-format.js";
import formatBytes from "@/helpers/format-bytes.js";
import { exportData } from "@/helpers/export-excel-data";

export default {
  name: "ProcessHistory",

  components: {
    Modal,
    Stepper,
    Sheet,
    FileIcon,
  },

  props: {
    processId: {
      type: Number,
      required: true,
    },

    selectedProcess: {
      type: Object,
      default: () => {},
    },

    externalAccess: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      historyTitle: "",
      processHistoryData: [],
      processHistoryModal: false,
      columns: [
        {
          id: this.$nano.id(),
          name: "stage",
          label: "STAGE",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "actionUser",
          label: "ACTION USER",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "receivedOn",
          label: "RECEIVED ON",
          type: "DATE",
        },
        {
          id: this.$nano.id(),
          name: "processedBy",
          label: "PROCESSED BY",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "processedOn",
          label: "PROCESSED ON",
          type: "DATE",
        },
        {
          id: this.$nano.id(),
          name: "status",
          label: "STATUS",
          type: "SHORT_TEXT",
        },
      ],
      stageLevel: [],
      currentStep: 0,
      selectedFile: {},
      fileSheet: false,
      selectedFiles: [],
    };
  },

  watch: {
    processId: {
      immediate: true,
      handler() {
        if (this.processId) {
          this.showHistory(this.processId);
        }
      },
    },
  },

  methods: {
    async showHistory(processId) {
      this.processHistoryModal = true;
      this.processHistoryData = [];
      this.historyTitle = this.selectedProcess.requestNo;

      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await workflow.processHistory(
        this.selectedProcess.id,
        processId
      );
      this.$store.commit("hideLoadingBarSave");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        payload.forEach((item) => {
          this.processHistoryData.push({
            ...item,
            showComments: false,
            showAttachments: false,
            showSubWorkflowHistory: false,
          });
        });

        this.stageLevel = [];
        this.currentStep = 0;
        payload.forEach((row) => {
          if (row.actionStatus !== 2) {
            if (
              this.stageLevel.filter((stage) => stage.id === row.activityId)
                .length === 0
            ) {
              this.stageLevel.push({
                id: row.activityId,
                label: row.stage,
                status: row.status,
              });
              if (
                ((row.actionUser || row.subWorkflowHistory) &&
                  row.status &&
                  row.status !== "Forward") ||
                (row.actionUser === null && !row.subWorkflowHistory)
              ) {
                this.currentStep += 1;
              }
            } else if (row.status) {
              if (
                this.stageLevel.filter(
                  (stage) =>
                    stage.id === row.activityId && stage.status !== row.status
                ).length
              ) {
                this.currentStep += 1;
              } else {
                this.currentStep -= 1;
              }
            } else {
              let index = this.stageLevel.findIndex(
                (stage) => stage.id === row.activityId
              );
              if (index > -1) {
                this.stageLevel.splice(index, this.stageLevel.length - index);
                this.stageLevel.push({
                  id: row.activityId,
                  label: row.stage,
                  status: row.status,
                });
                if (
                  (row.actionUser && row.status && row.status !== "Forward") ||
                  row.actionUser === null
                ) {
                  this.currentStep = index + 1;
                } else {
                  this.currentStep = index;
                }
              }
            }
          }
        });
      }
    },
    openFileByCmd(file) {
      const originalFile = file;
      const { fileName: name, itemid: id, ...rest } = originalFile;
      this.selectedFile = { name, id, ...rest };
      const allAttachments = this.processHistoryData
        .map((item) => item.attachments)
        .flat();
      this.selectedFiles = allAttachments.filter(
        (item) =>
          item.id == this.selectedFile.id && item.name == this.selectedFile.name
      );

      this.openFile(this.selectedFile);
    },
    fileSize(size) {
      return formatBytes(size);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          let type = 1;
          if (
            this.repositoryDetails.fieldsType === "STATIC" &&
            this.workflow.initiatedBy === "FORM"
          ) {
            type = 2;
          }
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=${type}`;
            } else if (origin === "https://trial.ezofis.com") {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=${type}`;
            } else if (origin === "https://app.ezofis.com1") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=${type}`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=${type}`;
            }
          } else if (origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=${type}`;
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/${type}`;
          }
        } else {
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.selectedProcess.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}`;
            } else if (
              origin === "https://trial.ezofis.com" &&
              !otherFilePDFSupport(ext)
            ) {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.selectedProcess.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}`;
            } else if (origin === "https://app.ezofis.com1") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.selectedProcess.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.selectedProcess.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}`;
            }
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&wId=${this.selectedProcess.id}&pId=${this.selectedProcess.processId}&type=2&filename=${this.selectedFile.name}`;
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
        }
      }
    },

    openFile(fileObj) {
      if (!fileObj.loading) {
        this.selectedFile = fileObj;
        let origin = location.origin;
        if (
          origin === "https://ag-appsvc01.azurewebsites.net" ||
          origin === "https://ag-appsvc05.azurewebsites.net"
        ) {
          window.open(this.fileLink());
        } else {
          this.fileSheet = true;
        }
      } else {
        this.$alert.info("Please wait to complete the uploading");
      }
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    closeModal() {
      this.processHistoryModal = false;
      this.$emit("close");
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    export_Data1() {
      let tableData = [];
      this.processHistoryData.forEach((item) => {
        tableData.push({
          Stage: item.stage,
          "Action User": item.actionUser,
          "Received On": this.$day.format(item.receivedOn),
          "Processed On": this.$day.format(item.processedOn),
          "Processed By": item.processedBy,
          Status: item.status,
        });
      });
      exportData(tableData, `${this.historyTitle} - Process History`);
    },

    export_Data() {
      let tableData = [];
      this.processHistoryData.forEach((item) => {
        tableData.push({
          Stage: item.stage,
          "Action User": item.actionUser || "",
          "Received On": this.$day.format(item.receivedOn) || "",
          "Processed On": this.$day.format(item.processedOn) || "",
          "Processed By": item.processedBy || "",
          Status: item.status || "",
        });

        if (item.comments) {
          if (item.comments.length) {
            let fileNames;
            tableData.push({
              Stage: `<span style="color: #00BCD4;font-weight: bold;">Comments(${item.comments.length})</span>`,
            });

            tableData.push({
              Stage: `<strong>Comments<strong>`,
              "Action User": `<strong>Commented By<strong>`,
              "Received On": `<strong>Commented On<strong>`,
              "Processed On": `<strong>Attached In<strong>`,
            });

            item.comments.forEach((item) => {
              if (item.embedFileList) {
                if (item.embedFileList.length) {
                  item.embedFileList.forEach((item) => {
                    fileNames = item.fileName;
                  });
                }
              }
              tableData.push({
                Stage: `<span style="color: #00BCD4;">${
                  item.comments || ""
                }</span>`,
                "Action User": `<span style="color: #00BCD4;">${
                  item.createdByEmail || ""
                }</span>`,
                "Received On": `<span style="color: #00BCD4;">${
                  this.$day.format(item.createdAt) || ""
                }</span>`,
                "Processed On": `<span style="color: #00BCD4;">${
                  fileNames || ""
                }</span>`,
              });
            });
          }
        }

        if (item.attachments) {
          if (item.attachments.length) {
            tableData.push({
              Stage: `<span style="color: #643094;font-weight: bold;">Attachment(${item.attachments.length})</span>`,
            });

            tableData.push({
              Stage: `<strong>File Name<strong>`,
              "Action User": `<strong>	Attached By<strong>`,
              "Received On": `<strong>Attached On<strong>`,
            });

            item.attachments.forEach((item) => {
              tableData.push({
                Stage: `<span style="color: #643094; border: 12px solid#50067e;">${
                  item.name || ""
                }</span>`,
                "Action User": `<span style="color: #643094;">${
                  item.createdByEmail || ""
                }</span>`,
                "Received On": `<span style="color: #643094;">${
                  this.$day.format(item.createdAt) || ""
                }</span>`,
              });
            });
          }
        }

        if (item.subWorkflowHistory) {
          if (item.subWorkflowHistory.length) {
            tableData.push({
              Stage: `<span style="color: #e97f06;font-weight: bold;">${item.subWorkflowHistory[0].requestNo}[Connector]<span>`,
            });
            tableData.push({
              Stage: `<strong>Stage</strong>`,
              "Action User": `<strong>Action User</strong>`,
              "Received On": `<strong>Received On</strong>`,
              "Processed On": `<strong>Processed On</strong>`,
              "Processed By": `<strong>Processed By</strong>`,
              Status: `<strong>status</strong>`,
            });
            item.subWorkflowHistory.forEach((item) => {
              tableData.push({
                Stage: `<span style="color: #e97f06;">${item.stage}</span>`,
                "Action User": `<span style="color: #e97f06;border:2px solid black">${
                  item.actionUser || ""
                }</span>`,
                "Received On": `<span style="color: #e97f06;">${
                  this.$day.format(item.receivedOn) || ""
                }</span>`,
                "Processed On": `<span style="color: #e97f06;">${
                  this.$day.format(item.processedOn) || ""
                }</span>`,
                "Processed By": `<span style="color: #e97f06;">${
                  item.processedBy || ""
                }</span>`,
                Status: `<span style="color: #e97f06;">${
                  item.status || ""
                }</span>`,
              });
            });
          }
        }
      });

      exportData(tableData, this.historyTitle + " Report");
    },
  },
};
</script>
<style lang="scss" scoped>
#modal #processHistory {
  #history {
    width: 100%;
    white-space: nowrap;
    margin-bottom: 15px;
    border: 1px solid var(--divider-color);
    border-radius: 10px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      padding: 8px;
      font-weight: 500;
    }

    th {
      border-bottom: 1px solid var(--divider-color);
    }

    tr:not(:first-child) td {
      border-top: 1px solid var(--divider-color); /* Add top border to the first row */
    }

    th:not(:last-child),
    td:not(:last-child) {
      border-right: 1px solid var(--divider-color); /* Add top border to the first row */
    }

    td.ellipsis:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }

    .comments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    .attachments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    #comments1 {
      width: 100%;
      white-space: nowrap;
      // margin-bottom: 15px;
      background: #fcfafa;
      table-layout: fixed;
      border: 1px solid var(--divider-color);
      border-radius: 10px;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;

          &.small {
            width: 70px;
          }

          &.medium {
            width: 90px;
          }

          &.large {
            width: 210px;
          }
        }
      }

      th {
        padding: 8px;
        font-weight: 500;
      }

      td {
        padding: 8px;
        font-weight: 500;

        &.commentsText {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.commentsText:hover {
          overflow: visible;
          white-space: normal;
          word-break: break-all;
        }
      }

      tr:not(:first-child) td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }

      th:not(:last-child),
      td:not(:last-child) {
        border-right: 1px solid var(--divider-color); /* Add top border to the first row */
      }
    }

    #attachments {
      width: 100%;
      white-space: nowrap;
      margin-bottom: 5px;
      background: #fcfafa;
      border: 1px solid var(--divider-color);
      border-radius: 10px;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;
        }
      }

      th,
      td {
        padding: 8px;
        font-weight: 500;
      }

      tr:not(:first-child) td {
        border-top: 1px solid var(--divider-color); /* Add top border to the first row */
      }

      th:not(:last-child),
      td:not(:last-child) {
        border-right: 1px solid var(--divider-color); /* Add top border to the first row */
      }

      .comments {
        color: var(--secondary);
        text-align: left;
        cursor: pointer;
      }
    }
  }
  .file-icons {
    display: flex;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .fileFrame {
  min-height: calc(100vh - 120px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet .mailTransactionList {
  #action-btn {
    width: 28px !important;
    height: 28px !important;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}
#sheet {
  #approvalSignature {
    background-color: var(--component-bg-color);
    border-radius: 4px;

    .title {
      @extend .text-md;
      font-weight: 600;
      text-transform: capitalize;
    }

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
    .text {
      text-align: center;
    }

    .imgCenter {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      height: 100px;
    }
  }
}
.file-link {
  color: blue;
  cursor: pointer;
  text-decoration: none;
}
</style>
<style lang="scss"></style>
